import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// material
import { alpha, experimentalStyled as styled } from "@material-ui/core/styles";
import { ListItem, ListItemText } from "@material-ui/core";

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&.isActiveRoot": {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "& .subIcon:before": {
      transform: "scale(2)",
      backgroundColor: theme.palette.primary.main,
    },
    "&:before": {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: "absolute",
      backgroundColor: theme.palette.primary.main,
    },
  },
  "&.isActiveSub": {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    "& .subIcon:before": {
      transform: "scale(2)",
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

// const getIcon = (name) => (
//   <SvgIconStyle
//     src={`/static/icons/navbar/${name}.svg`}
//     sx={{ width: 22, height: 22 }}
//   />
// );

// ----------------------------------------------------------------------

const SubIconStyle = styled("span")(({ theme }) => ({
  width: 22,
  height: 28,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:before": {
    width: 4,
    height: 4,
    content: "''",
    display: "block",
    borderRadius: "50%",
    backgroundColor: theme.palette.text.disabled,
    transition: theme.transitions.create("transform"),
  },
}));

SidebarPage.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  icon: PropTypes.string,
};

export default function SidebarPage({ title, href, icon }) {
  return (
    <ListItemStyle
      button
      to={href}
      disableGutters
      component={RouterLink}
      activeClassName="isActiveRoot"
      isActive={(match, location) => {
        if (!match) {
          return false;
        }

        const { url } = match;
        const { pathname } = location;

        const isMatch = url === pathname;

        return isMatch;
      }}
    >
      {/* <ListItemIcon></ListItemIcon> */}
      <SubIconStyle className="subIcon" />
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  );
}
