import { Icon } from "@iconify/react";

import { useRef, useState } from "react";
import bellFill from "@iconify/icons-eva/bell-fill";
// material
import { Box, Divider, Typography, Badge } from "@material-ui/core";
// utils
// components!
import MenuPopover from "../MenuPopover";
import { MIconButton } from "../@material-extend";
import { useTable } from "@motor-js/engine";

// ----------------------------------------------------------------------

export default function QlikNotifications() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const cols = [
    {
      qField: "dummy",
      dataKey: "dummy",
      qLabel: "dummy",
    },
    {
      qField: "=date(ReloadTime(),'WWW dd MMM YYYY hh:mm TT')",
      dataKey: "reloadtime",
      qLabel: "reloadtime",
    },

    {
      qField:
        "=date(max({1<sales_local={" + '">0"' + "}>} dte),'WWW dd MMM YYYY')",
      dataKey: "dataAsOf",
      qLabel: "dataAsOf",
    },
    {
      qField:
        "=if(date(max({1<sales_local={" +
        '">0"' +
        "}>} dte))=Date(Today()-1),1,0)",
      dataKey: "dataCheck",
      qLabel: "dataCheck",
    },
    {
      qField: "=only({1<weekid={'$(currentMaxWeekID)'}>} [week])",
      dataKey: "currentReportWeek",
      qLabel: "currentReportWeek",
    },
  ];

  const { dataSet } = useTable({
    cols,
  });

  const reloadtime =
    dataSet === undefined ? "nm" : dataSet[0]["reloadtime"].text;

  const dataAsOf = dataSet === undefined ? "nm" : dataSet[0]["dataAsOf"].text;

  const dataCheck = dataSet === undefined ? 0 : dataSet[0]["dataCheck"].number;

  const currentReportWeek =
    dataSet === undefined ? 0 : dataSet[0]["currentReportWeek"].text;

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        color={open ? "primary" : "default"}
      >
        <Badge
          badgeContent={""}
          color={dataCheck === 1 ? "success" : "error"}
          variant="dot"
        >
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Data Reloaded on {reloadtime}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Data as of {dataAsOf}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Current week is {currentReportWeek}
            </Typography>
          </Box>
        </Box>

        <Divider />
      </MenuPopover>
    </>
  );
}
