// this navbar contains the search bar

import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, experimentalStyled as styled } from "@material-ui/core/styles";
import { AppBar, Hidden, Toolbar, IconButton, Box } from "@material-ui/core";
//
import QlikSelections from "../../components/qlik/QlikSelections";
import QlikSearch from "../../components/qlik/QlikSearch";
import QlikNotifications from "../../components/qlik/QlikNotifications";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <Hidden lgUp>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>

        <QlikSearch />
        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > *:not(:first-of-type)": {
              ml: { xs: 0.5, sm: 2, lg: 3 },
            },
          }}
        >
          <QlikSelections />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ml: { xs: 0.5, sm: 2, lg: 1 },
          }}
        >
          <QlikNotifications />
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
